import React from 'react';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import { Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Blogs from './pages/blogs/Blogs';
import Contact from './pages/contact/Contact';
import News from './pages/news/News';
import ScrollToTop from './components/ScrollToTop';
import Gallery from './pages/gallery/Gallery';
import BlogDetail from './pages/blogs/BlogDetail';
import { FaBlog } from 'react-icons/fa';
import BlogDetail2 from './pages/blogs/BlogDetail2';
import Donation from './pages/donation/Donation';
import CsrInitiatives from './pages/csr/CsrInitiatives';
import Volunteer from './pages/volunteer/Volunteer';
import BlogDetails3 from './pages/blogs/BlogDetails3';


const App = () => {
  return (
    <>
      <Navbar />
      <ScrollToTop />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/news' element={<News />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/blogdetail' element={<BlogDetail />} />
        <Route path='/blogdetails2' element={<BlogDetail2 />} />
        <Route path='/blogdetails3' element={<BlogDetails3 />} />
        <Route path='/donation' element={<Donation />} />
        <Route path='/csr' element={<CsrInitiatives />} />
        <Route path='/volunteer' element={<Volunteer />} />

      </Routes>
      <Footer />


    </>
  );
};

export default App;
