import React from "react";
import { Navigation, Pagination, Autoplay, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./slider.css";

const Slider = () => {
  return (
    <>
      <div className="align-self-center">
        <div className="swiper-container">
          <Swiper
            modules={[Navigation, Pagination, Autoplay, A11y]}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            loop={true}
          >
            <SwiperSlide>
              <img
                src="/images/1.png"
                alt="slide"
                className="swiper-slide-image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/images/2.png"
                alt="slide"
                className="swiper-slide-image"
              />
            </SwiperSlide>

            <SwiperSlide>
              <img
                src="/images/4.png"
                alt="slide"
                className="swiper-slide-image"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Slider;
