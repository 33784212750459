import React from "react";
import { TextField, Button } from "@mui/material";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <section className="abt-01">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-wrapper">
                <h3>Contact Us</h3>
                <ol>
                  <li>
                    Home<i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>Contact Us</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="contact_container container-width-auto container vh-50">
        <div className="row">
          <div className="col-12">
            <div className="heading my-5">
              <h2>Contact Us</h2>
            </div>
          </div>
        </div>

        <div className="row align-items-center container-xl">
          <div className="col d-block">
            <div className="map-responsive ">
              <iframe
                className="gmap_iframe border p-2"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14995.1934260002!2d73.843284!3d20.016976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddc1b308290909%3A0x95f4d9d4823cca8c!2sVenkateshwara%20Co-operative%20Power%20and%20Agro%20Processing%20Ltd.!5e0!3m2!1sen!2sus!4v1714901624111!5m2!1sen!2sus"
              ></iframe>
            </div>
          </div>
          <div className="col">
            <div className="single_address">
              <i className="fa fa-map-marker" />
              <h4>Our Address</h4>
              <p>
                S.NO.256/2, WING-T, FL. NO. T-101, PARKSYDE HOMES, NASHIK,
                MAHARASHTRA, INDIA, 422003
              </p>
            </div>
            <div className="single_address">
              <i className="fa fa-envelope" />
              <h4>Send your message</h4>
              <p>contact@venkateshwarawelfarefoundation.com</p>
            </div>
            <div className="single_address">
              <i className="fa fa-phone" />
              <h4>Call us on</h4>
              <p> 02532304220 </p>
            </div>
            {/* <div className="single_address">
              <i className="fa fa-clock" />
              <h4>Work Time</h4>
              <p>
                Mon - Fri: 08.00 - 16.00. <br />
                Sat: 10.00 - 14.00
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
