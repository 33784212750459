import React from "react";
import Volunteer from "../volunteer/Volunteer";
import { NavLink } from "react-router-dom";

const Donation = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading my-5">
              <h2>Donation</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-2">
        <h1>Your Help Matters</h1>
        <img
          src="https://give.do/blog/wp-content/uploads/2023/08/The-role-of-the-education-NGO-in-India-enthusiastic-children-beneficiaries-education-classroom-preview.jpg"
          alt=""
          className="img-fluid"
        />
      </div>

      {/* <div style={{ backgroundImage: "url(/images/s6.jpg)" }}> */}
      <div className="container p-3">
        <div className="bg-white shadow-sm p-5">
          <h1>Why Donate?</h1>
          <p>
            Supporting farmers' children's education is an investment in the
            future of agriculture and our society as a whole. By donating,
            you're not only providing access to quality education but also
            breaking the cycle of poverty and opening doors to new
            possibilities. Your contribution helps these young minds thrive,
            equipping them with the skills and knowledge they need to become
            leaders and innovators in the agricultural sector. Together, we can
            cultivate a generation that will sustainably steward our land and
            drive progress in rural communities.
          </p>
          <div className="btn btn-success rounded-0 btn-lg">
            <NavLink to="/contact" className="text-white text-decoration-none">
              Donate Now
            </NavLink>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* <Volunteer /> */}
    </>
  );
};

export default Donation;
