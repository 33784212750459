import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Col, Container, Form, Row } from "react-bootstrap";

const Volunteer = () => {
  return (
    <>
      <div className="row ">
        <div className="col-md-6">
          <div className="text-center">
            <img src="/images/volunteer.jpeg" className="img-fluid" alt="" />
          </div>
        </div>

        <div className="col-md-6 align-self-center my-3">
          <div className=" justify-content-center">
            <h3>BECOME A VOLUNTEER</h3>
            <Form>
              <TextField
                name="firstname"
                margin="dense"
                type="text"
                placeholder="Enter Your First Name"
                variant="outlined"
                label="  Name"
                // value={values.firstname}
                // onChange={handleChange}
                fullWidth
                required
              ></TextField>
              {/* {errors.firstname ? (
                <p className="text-danger mb-0">{errors.firstname}</p>
              ) : null} */}

              <TextField
                name="email"
                margin="dense"
                type="text"
                placeholder="Enter Your Email ID"
                variant="outlined"
                label="Email"
                // value={values.firstname}
                // onChange={handleChange}
                fullWidth
                required
              ></TextField>
              {/* {errors.firstname ? (
                <p className="text-danger mb-0">{errors.firstname}</p>
              ) : null} */}
              <TextField
                name="message"
                margin="dense"
                type="text"
                placeholder="Message"
                variant="outlined"
                label="Message"
                // value={values.firstname}
                // onChange={handleChange}
                fullWidth
                required
              ></TextField>
              {/* {errors.firstname ? (
                <p className="text-danger mb-0">{errors.firstname}</p>
              ) : null} */}
              <button
                type="submit"
                className="px-3 my-1 btn btn-success text-white "
                // disabled={processing || setProcessing}
              >
                {/* {processing ? "Submitting..." : "Submit"} */} Submit
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Volunteer;
