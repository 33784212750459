import React from "react";
import { CiCalendarDate } from "react-icons/ci";
import Volunteer from "../volunteer/Volunteer";

const News = () => {
  const data = [
    {
      id: 1,
      img: "https://www.venkateshwarapoweragro.com/Awards/news5.png",
      title: "Title",
      date: "3 june, 2023",
      desc: "कृषी विज्ञान संकुल काष्टी येथील विद्यार्थ्यांनी व्यंकटेश्वरा को-ऑपरेटिव्ह पावर अँड ऍग्रो प्रोसेसिंग लिमिटेड फार्मवर पूर्ण केले आपले शेती विषयी प्रशिक्षण. ",
    },
    {
      id: 2,
      img: "https://www.venkateshwarapoweragro.com/Awards/news5.png",
      title: "Title",
      date: "3 june, 2023",
      desc: "कृषी विज्ञान संकुल काष्टी येथील विद्यार्थ्यांनी व्यंकटेश्वरा को-ऑपरेटिव्ह पावर अँड ऍग्रो प्रोसेसिंग लिमिटेड फार्मवर पूर्ण केले आपले शेती विषयी प्रशिक्षण. ",
    },
    {
      id: 3,
      img: "https://www.venkateshwarapoweragro.com/Awards/news5.png",
      title: "Title",
      date: "3 june, 2023",
      desc: "कृषी विज्ञान संकुल काष्टी येथील विद्यार्थ्यांनी व्यंकटेश्वरा को-ऑपरेटिव्ह पावर अँड ऍग्रो प्रोसेसिंग लिमिटेड फार्मवर पूर्ण केले आपले शेती विषयी प्रशिक्षण. ",
    },
    {
      id: 4,
      img: "https://www.venkateshwarapoweragro.com/Awards/news5.png",
      title: "Title",
      date: "3 june, 2023",
      desc: "कृषी विज्ञान संकुल काष्टी येथील विद्यार्थ्यांनी व्यंकटेश्वरा को-ऑपरेटिव्ह पावर अँड ऍग्रो प्रोसेसिंग लिमिटेड फार्मवर पूर्ण केले आपले शेती विषयी प्रशिक्षण. ",
    },
  ];
  return (
    <>
      <section className="abt-01">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-wrapper">
                <h3>News</h3>
                <ol>
                  <li>
                    Home<i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>News</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading my-5">
              <h2>News</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {/* <div className="row"> */}
        {data.map((item) => {
          return (
            <>
              <div className="row shadow-sm my-4">
                <div className="col-md-4 align-self-center align-self-center">
                  <img src={item.img} className="img-fluid" alt="" />
                </div>
                <div className="col-md-6 ">
                  <div className="">
                    <p className="">
                      <CiCalendarDate />
                      February 20, 2024
                    </p>
                  </div>
                  <hr />
                  <h5>{item.title}</h5>
                  <p>{item.desc}</p>
                </div>
              </div>
            </>
          );
        })}
        {/* </div> */}
      </div>

      <Volunteer />
    </>
  );
};

export default News;
