import React, { useState } from "react";
import "./navbar.css";
import { NavLink } from "react-router-dom";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineLinkedin,
  AiFillMail,
  AiFillContacts,
} from "react-icons/ai";
import { IoIosMailUnread } from "react-icons/io";
import { FaPhone } from "react-icons/fa";

const Navbar = () => {
  const [isProjectsDropdownOpen2, setIsProjectsDropdownOpen2] = useState(false);

  const handleProjectsDropdownOpen2 = () => {
    setIsProjectsDropdownOpen2(true);
  };

  const handleProjectsDropdownClose2 = () => {
    setIsProjectsDropdownOpen2(false);
  };
  return (
    <>
      <div className="row my-1 gx-0">
        <div className="col-md-1 text-center  mt-2  align-self-center ">
          <div className=" ms-2">
            <div className=" ">
              {/* <a
                  href="mailto:gardens_sup@nmc.gov.in"
                  className="text-decoration-none text-dark"
                >
                  contact@venkateshwarawelfarefoundation.com
                </a> */}
              <a className="navbar-brand" href="/">
                <img src="/logo.png" width={150} alt="" />
                {/* <h3 className="text-success fw-bold">Farmers Welfare Foundation</h3> */}
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-7 text-center align-self-center">
          <div className="text-success m-0 align-text-center mt-1">
            <h3 className="name">Venkateshwara Farmer Welfare Foundation</h3>
            <b style={{ color: "rgb(255, 103, 15)" }}>
              Registered Under Government of India's Ministry of Corporate
              Affairs
            </b>
            <br />
            <b>REG.NO.(U85300MH2021NPL371644)</b>
          </div>
        </div>
        <div className="col-md-4 text-end mt-1 align-items-center">
          <div className="d-inline-flex me-5 ">
            <div className="col py-2 m-0">
              {/* <p className="text-decoration-none text-dark">Follow Us :</p> */}
            </div>
            <div className="social-media d-flex ms-4">
              <ul>
                <li>
                  <a href="" className="text-white  ">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/venkateshwarawelfare-foundation-8118a2304/"
                    className="text-white  "
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/VenkateshwarWF"
                    className="text-white  "
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/venkateshwarawelfare-foundation-8118a2304/"
                    className="text-white  "
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <a
              href="mailto:contact@venkateshwarawelfarefoundation.com"
              className="text-decoration-none text-dark me-2"
            >
              <IoIosMailUnread className="text-success fs-4" />{" "}
              contact@venkateshwarawelfarefoundation.com
            </a>
            <br />
            <p className="text-decoration-none text-dark me-2">
              <FaPhone className="text-success me-2" />
              <a
                href="tel:02532304220"
                className="text-decoration-none text-dark me-2"
              >
                02532304220
              </a>
              {/* |
              <a
                href="tel:+917875496662"
                className="text-decoration-none text-dark ms-2"
              >
                +917875496662
              </a> */}
            </p>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg shadow-sm text-uppercase">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link " aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/gallery">
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/csr">
                  CSR Intiatives
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className=" nav-link" to="/donation">
                  Donate
                </NavLink>
              </li>
              <div
                className="nav-item dropdown"
                onMouseEnter={handleProjectsDropdownOpen2}
                onMouseLeave={handleProjectsDropdownClose2}
              >
                <a
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Media
                </a>
                <div
                  className={`dropdown-menu m-0 ${
                    isProjectsDropdownOpen2 ? "show" : ""
                  }`}
                >
                  <NavLink
                    to="/blogs"
                    className="dropdown-item"
                    // onClick={() => scrollToDown(480)}
                  >
                    Blogs
                  </NavLink>
                  {/* <NavLink
                    to="/news"
                    className="dropdown-item"
                    // onClick={() => scrollToDown(480)}
                  >
                    News
                  </NavLink> */}
                  {/* <NavLink to="/milestone" className="dropdown-item">
                    Milestones
                  </NavLink> */}
                </div>
              </div>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
