import React, { useState, useEffect } from "react";
import {
  AiFillCloseCircle,
  AiFillRightCircle,
  AiFillLeftCircle,
} from "react-icons/ai";
import "./gallery.css";

const Gallery = () => {
  document.title = "Gallery";
  const galleryImages = [
    {
      img: "/gallery/DSC_0227_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0233_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0237_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0243_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0244_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0258_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0313_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0319_11zon.jpg",
      tag: "all",
    },

    // { img: "/all/chitra_wagh.jpg", tag: "all" },
    // { img: "/all/DSC_1488.jpg", tag: "all" },
    {
      img: "/gallery/DSC_0330_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0342_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0343_11zon.jpg",
      tag: "dairy",
    },
    {
      img: "/gallery/DSC_0348_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0396_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0491_11zon.jpg",
      tag: "dairy",
    },
    {
      img: "/gallery/DSC_0492_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0493_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0502_11zon.jpg",
      tag: "dairy",
    },
    {
      img: "/gallery/DSC_0506_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0507_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0509_11zon.jpg",
      tag: "dairy",
    },
    {
      img: "/gallery/DSC_0518 (1)_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0520_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0554_11zon.jpg",
      tag: "dairy",
    },

    {
      img: "/gallery/DSC_0565_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0609_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0619_11zon.jpg",
      tag: "dairy",
    },

    {
      img: "/gallery/DSC_0621_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/DSC_0638_11zon.jpg",
      tag: "all",
    },

    {
      img: "/gallery/DSC_0670_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/IMG-20220129-WA0079_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/IMG-20220130-WA0033_11zon.jpg",
      tag: "dairy",
    },
    {
      img: "/gallery/IMG-20220130-WA0035_11zon.jpg",
      tag: "all",
    },
    {
      img: "/gallery/IMG-20220130-WA0038_11zon.jpg",
      tag: "dairy",
    },
    {
      img: "/gallery/school_vanbhojan2.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan3.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan4.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan5.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan6.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan7.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan8.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan9.jpg",
      tag: "all",
    },
    ,
    {
      img: "/gallery/school_vanbhojan10.jpg",
      tag: "all",
    },
  ];

  const [tag, setTag] = useState("all");
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    tag === "all"
      ? setFilteredImages(galleryImages)
      : setFilteredImages(galleryImages.filter((image) => image.tag === tag));
  }, [tag]);

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  //open modal
  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // close modal
  const handleCloseModal = (index) => {
    setOpenModal(false);
  };

  //left slide
  const preSlide = (index) => {
    slideNumber === 0
      ? setSlideNumber(galleryImages.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  //right slide
  const nextSlide = (index) => {
    slideNumber + 1 === galleryImages.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading my-5">
              <h2>Gallery</h2>
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <div className="sliderWrap1">
          <AiFillCloseCircle className="btnclose" onClick={handleCloseModal} />
          <AiFillLeftCircle className="btnleft" onClick={preSlide} />
          <AiFillRightCircle className="btnright" onClick={nextSlide} />
          <div className="fullScreenImage1">
            <img src={filteredImages[slideNumber].img} alt="" />
            {/* <p className="text-white">
              "वेंकटेश्वरा पॉवर अग्रो लिमिटेडला देवेंद्र फडणवीसजींनी दिलेला
              पुरस्कार, ऊनचंद ऊर्जा, ऊनचंद साखर, एक साथ, एक दिशेने प्रगल्भ आणि
              समृद्ध या विजयी यात्रेचं नेतृत्व!"
            </p> */}
          </div>
        </div>
      )}

      <div className=" gallery-btn text-center ">
        <div className="row">
          <div className="galleryWrap1 mt-2">
            {filteredImages.map((slide, index) => {
              return (
                <div
                  className="single1"
                  key={index}
                  onClick={() => handleOpenModal(index)}
                >
                  <img
                    src={slide.img}
                    alt="..."
                    className="border border-2 card-img-top"
                    height={200}
                  />
                  <div className="card-body">
                    <h5 className="text-success mt-2">{slide.title}</h5>
                    <p>{slide.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
const TagButton = ({ name, handleSetTag, activeTag }) => {
  return (
    <>
      <button
        className={`btn btn-success w-75 rounded-3 gallery-tag m-1 ${
          activeTag === name ? "active btn btn-outline" : ""
        }`}
        onClick={() => handleSetTag(name)}
      >
        {name.toUpperCase()}
      </button>
    </>
  );
};
export default Gallery;
