import React from "react";
import Volunteer from "../volunteer/Volunteer";
import { NavLink } from "react-router-dom";

function CsrInitiatives() {
  return (
    <>
      <div>
        <img src="/images/in.jpg" className="img-fluid" alt="" />
        <div
          className="row justify-content-center  p-5"
          style={{ marginTop: "-80px" }}
        >
          <div className="col-md-9 bg-success text-white">
            <h4 className="p-4">
              Corporate Social Responsibility (CSR) Initiatives: Supporting
              Farmer Communities
              <hr className="w-25 custom-hr" size="2px" />
            </h4>
          </div>

          <div className="container my-4">
            <h3>Welcome to Our CSR Initiatives Page!</h3> <hr />
            <p>
              At Venkateshwara Farmer Welfare Foundation (VFWF), we are
              dedicated to uplifting the lives of farmers and their families
              across India. We understand the challenges faced by the
              agricultural sector, and we strive to bridge the gap towards a
              more prosperous and sustainable future for our farmers.
            </p>
            <p>
              As a registered non-profit organization, VFWF is proud to hold an
              80G certificate issued by the Income Tax Department of India. This
              certification allows us to accept vital Corporate Social
              Responsibility (CSR) funding from companies committed to giving
              back to society. Your CSR contribution to VFWF will directly
              support our impactful initiatives designed to empower farmers and
              their families in five key areas:
            </p>
            <p>
              By partnering with VFWF through CSR funding, your company can make
              a significant and lasting impact on the lives of farmers and their
              families in rural India. Your contribution will directly translate
              into improved education for children, better health outcomes for
              families, enhanced skills for young farmers, increased access to
              resources, and empowered women driving positive change in their
              communities. Together, we can build a more sustainable and
              prosperous future for Indian agriculture.
            </p>
            <b>
              Become a CSR partner with Venkateshwara Farmer Welfare Foundation
              today and invest in the future of our farmers!
            </b>
            <br />
            <div className="btn btn-success rounded-0 btn-lg mt-3">
              <NavLink
                to="/contact"
                className="text-white text-decoration-none"
              >
                Donate Now
              </NavLink>
            </div>
            <h2 className="mt-4">Key Focus Areas</h2> <hr />
            <div className="row mt-5">
              <div className="col-md-4 my-3 ">
                <div className="card" style={{ height: "38rem" }}>
                  <img
                    src="https://www.just-style.com/wp-content/uploads/sites/27/2023/10/shutterstock_1204850986.jpg"
                    alt=""
                    height={250}
                  />
                  <div className="card-body">
                    <h4>Education for the Next Generation</h4>
                    <p>
                      We believe that education is the cornerstone of progress.
                      Through CSR funding, we can provide scholarships and
                      educational support programs for the children of farmers.
                      This will ensure they have access to quality education,
                      opening doors to new opportunities and breaking the cycle
                      of poverty. We facilitate after-school programs, bridge
                      courses, and career counseling workshops to equip these
                      young minds with the knowledge and skills needed to thrive
                      in the 21st century.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-3">
                <div className="card" style={{ height: "38rem" }}>
                  <img
                    src="https://farm2facts.org/wp-content/uploads/2020/06/Essential-Healthcare-Workers.png"
                    alt=""
                    height={250}
                  />
                  <div className="card-body">
                    <h4>
                      Investing in the Health and Well-being of Farmers'
                      Families
                    </h4>
                    <p>
                      : The well-being of farmers and their families is
                      paramount to a healthy agricultural ecosystem. CSR funding
                      allows us to organize medical camps in rural areas,
                      providing access to free health checkups, consultations
                      with doctors, and essential medications. We also conduct
                      workshops on preventative healthcare, hygiene practices,
                      and nutrition to promote a healthy lifestyle for farmers
                      and their families.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-3">
                <div className="card" style={{ height: "38rem" }}>
                  <img
                    src="https://static.country-guide.ca/wp-content/uploads/2019/02/14163052/farmer-data-962953374-valentinrussanov-GettyImages.jpg"
                    alt=""
                    height={240}
                  />
                  <div className="card-body">
                    <h4>Building the Skills of Young Farmers</h4>
                    <p>
                      The future of agriculture rests on the shoulders of the
                      next generation. Through CSR funding, VFWF can offer skill
                      development and training programs for young farmers. These
                      programs equip them with modern farming techniques,
                      sustainable practices, and knowledge on the latest
                      agricultural technologies. We conduct workshops on topics
                      such as soil management, water conservation, precision
                      agriculture, and market access, empowering young farmers
                      to become successful agri-entrepreneurs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-3">
                <div className="card" style={{ height: "38rem" }}>
                  <img
                    src="https://i0.wp.com/energynews.us/wp-content/uploads/2021/08/7834037780_301440e868_k.jpg?fit=2048%2C1366&ssl=1"
                    alt=""
                    height={240}
                  />
                  <div className="card-body">
                    <h4>Facilitating Access to Essential Resources</h4>
                    <p>
                      Financial constraints often limit farmers' ability to
                      procure vital resources needed for successful cultivation.
                      CSR funding allows VFWF to provide crucial support to
                      farmers by facilitating access to high-quality seeds,
                      fertilizers, and essential farm machinery and equipment at
                      subsidized rates. This not only improves agricultural
                      productivity but also empowers farmers to become
                      self-sufficient and achieve greater financial security.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 my-3">
                <div className="card" style={{ height: "38rem" }}>
                  <img
                    src="https://bettercotton.org/wp-content/uploads/2023/03/IMG-20230304-WA0005.jpg"
                    alt=""
                    height={240}
                  />
                  <div className="card-body">
                    <h4>Empowering Farmers' Wives</h4>
                    <p>
                      Farmers' wives play a critical role in agricultural
                      households, contributing significantly to farm management
                      and family well-being. VFWF recognizes their invaluable
                      contribution. Through CSR funding, we can empower farmers'
                      wives by conducting skill development programs in areas
                      such as organic farming, post-harvest management, value
                      addition techniques, and micro-entrepreneurship. These
                      programs not only empower women but also generate
                      additional income sources for farm families, fostering
                      greater financial stability and social inclusion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Volunteer />
    </>
  );
}

export default CsrInitiatives;
