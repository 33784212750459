import Aos from "aos";
import React, { useEffect } from "react";
import Volunteer from "../volunteer/Volunteer";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, disable: "mobile" });
  });

  return (
    <>
      {/* <!-- ============abt-01 Section  Start============ --> */}

      <section className="abt-01">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-wrapper">
                <h3>About Us</h3>
                <ol>
                  <li>
                    Home<i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>About Us</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- =========================================ABOUT US>>>START========================================= --> */}

      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading my-1">
                <h2>ABOUT US</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7 col-12">
              <div className="_lk_fr_er">
                <p style={{ textAlign: "justify" }} data-aos="fade-right">
                  Welcome to the Venkateshwara Welfare Foundation, dedicated to
                  uplifting farmers and agricultural communities worldwide. Our
                  foundation is driven by the core values of empowerment,
                  education, and environmental sustainability. We strive to
                  enhance farmer well-being by providing access to crucial
                  resources, information, and support services. Through
                  collaborations with local partners and stakeholders, we
                  address the challenges faced by farmers, ensuring they have
                  the tools they need to thrive. Empowering the next generation
                  of farmers is a central focus of our work. We offer
                  scholarships, training programs, and mentorship opportunities
                  to equip young individuals with the skills and knowledge
                  necessary for success in modern agriculture. Moreover, we
                  bridge the knowledge gap by providing practical guidance and
                  support to young farmers, helping them adapt to evolving
                  farming practices and technologies. Our commitment extends to
                  supporting farmer wives through income-generating
                  opportunities and skill development programs, recognizing
                  their integral role in agricultural households. At the
                  forefront of our efforts is the promotion of environmentally
                  friendly and sustainable farming methods. Through education
                  and advocacy, we aim to minimize agriculture's environmental
                  impact while maximizing its potential to contribute to food
                  security and rural development. Join us in our mission to
                  build a brighter future for farmers and cultivate sustainable
                  agricultural practices globally.
                </p>
              </div>
            </div>

            <div
              className="col-lg-5 col-12 align-self-center"
              data-aos="fade-left"
            >
              <img src="/images/aboutus.jpg" alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="heading my-5">
                <h2>Chairwoman's Message</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 text-center" data-aos="fade-right">
              <img src="/images/chair.png" className="img-fluid" alt="" />
            </div>
            <div
              className="col-md-6 mt-3"
              style={{ textAlign: "justify" }}
              data-aos="fade-left"
            >
              Our foundation is dedicated to empowering and uplifting farmers
              across the country. We believe that farmers are the backbone of
              our nation, and we are committed to providing them with the
              resources and support they need to succeed. Through our various
              initiatives, we aim to improve agricultural practices, promote
              sustainable farming methods, and ensure fair market prices for
              farmers' crops. We also work to improve the lives of farmers'
              families by providing access to education, healthcare, and other
              essential services. We invite you to explore our website and learn
              more about our mission and work. We also encourage you to get
              involved in our efforts by donating, volunteering, or simply
              spreading awareness about the challenges faced by farmers in
              India. <br />{" "}
              <p>
                Thank you for your interest in the Venkateshwara Farmers Welfare
                Foundation.
              </p>
              Sincerely, <br />
              <br />
              <b>Mukta Shivaji Dole</b>
              <p>Chairwoman, Venkateshwara Farmers Welfare Foundation</p>
            </div>
          </div>

          <div className="container py-4 mt-4">
            <div className="row">
              <div className="col-md-6">
                <h3>Vision</h3>
                <p style={{ textAlign: "justify" }}>
                  At the Venkateshwara Farmers Welfare Foundation, we are
                  committed to building a resilient agricultural ecosystem that
                  empowers farmers and uplifts rural communities. Our vision is
                  to create a future where farmers are equipped with the tools,
                  support, and respect they deserve as custodians of the land.
                  We believe in providing opportunities for farmers' children to
                  pursue quality education and contribute meaningfully to
                  society. Through our initiatives, we aim to instill innovative
                  practices that ensure the long-term sustainability and growth
                  of agriculture. Additionally, we prioritize the economic
                  empowerment of women in rural areas, fostering stronger and
                  more supportive family structures. Together, we are shaping a
                  future where agriculture is not just sustainable, but also a
                  source of pride and prosperity for all.
                </p>
              </div>
              <div className="col-md-6 text-center align-self-center">
                <img src="/images/s.jpeg" className="img-fluid" alt="" />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6  text-center align-self-center">
                <img src="/images/s1.jpeg" className="img-fluid" alt="" />
              </div>
              <div className="col-md-6">
                <h3>Mission</h3>
                <p style={{ textAlign: "justify" }}>
                  The Venkateshwara Farmers Welfare Foundation is dedicated to
                  catalyzing positive change in farming communities. We offer
                  financial literacy, disaster relief, and advocacy for fair
                  agricultural policies. Our scholarships and career counseling
                  empower the next generation, while training programs equip
                  young farmers with sustainable practices and market access
                  strategies. Recognizing women's vital role, we provide them
                  with income-generating skills, fostering economic independence
                  and family stability. We facilitate knowledge exchange among
                  farmers, promoting innovation and advancement. Advocating for
                  sustainable practices ensures the long-term viability of
                  agriculture. Through collaboration and comprehensive efforts,
                  we envision a future where agriculture thrives, empowering
                  rural families and contributing to a secure food system for
                  all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Volunteer />
    </>
  );
};

export default About;
