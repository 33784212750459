import React, { useEffect } from "react";
import Slider from "../../components/slider/Slider";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import Volunteer from "../volunteer/Volunteer";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, disable: "mobile" });
  });

  const data = [
    {
      id: 1,
      img: "/images/b.png",
      date: "5 june, 2024",
      title: "Securing a Brighter Future",
      desc: "Financial Literacy Workshops: Equipping farmers with financial management skills is crucial. Workshops on budgeting, saving, and accessing credit facilities can help them make informed financial decisions and weather economic uncertainties.",
      link: "/blogdetail",
    },
    {
      id: 2,
      img: "/images/b1.png",
      date: "5 june, 2024",
      title: "Elevating Farmers' Well-being",
      desc: "Affordable Healthcare Access: Many farmers lack access to affordable healthcare services. VWFF can partner with healthcare providers to offer subsidized medical check-ups, essential medication at reduced costs, or organize mobile health camps in rural areas.",
      link: "/blogdetails2",
    },
    {
      id: 3,
      img: "/images/Venkateshwara Farmers Welfare Foundation is transforming lives and cultivating a brighter tomorrow for farmers across India through comprehensive support programs and empowering initiatives (1).png",
      date: "5 june, 2024",
      title: "Cultivating the Future",
      desc: "The fertile fields of India hold immense potential, a promise of bountiful harvests that nourish generations. However, this promise hinges on a crucial factor: the next generation of farmers.",
      link: "/blogdetails3",
    },
  ];

  return (
    <>
      <Slider />

      {/* <section>
        <div className="container py-5 my-5">
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="shadow p-3 rounded-3">
                <b className="fs-1 bg-light p-4 border rounded-5 ">
                  <CiUser className="my-5" />
                </b>
                <h3>ABOUT US</h3>
                <p>
                  Welcome to the Venkateshwara Welfare Foundation, dedicated to
                  uplifting farmers and agricultural communities worldwide. Our
                  foundation is driven by the core values of empowerment,
                  education, and environmental sustainability.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="shadow p-3 rounded-3">
                <b className="fs-1 bg-light p-4 border rounded-5 ">
                  <CiUser className="my-5" />
                </b>
                <h3>VISION</h3>
                <p>
                  At the Venkateshwara Farmers Welfare Foundation, we are
                  committed to building a resilient agricultural ecosystem that
                  empowers farmers and uplifts rural communities.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="shadow p-3 rounded-3">
                <img
                  src="/images/mission.avif"
                  width={100}
                  className="my-2"
                  alt=""
                />

                <h3>MISSION</h3>
                <p>
                  The Venkateshwara Farmers Welfare Foundation is dedicated to
                  catalyzing positive change in farming communities. We offer
                  financial literacy, disaster relief, and advocacy for fair
                  agricultural policies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* about */}
      <div className="about-us container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2>About Us</h2>
              </div>
            </div>
          </div>
          <div className="row w-100">
            <div className=" col-md-6">
              <p style={{ textAlign: "justify" }} data-aos="fade-right">
                Welcome to the Venkateshwara Welfare Foundation, dedicated to
                uplifting farmers and agricultural communities worldwide. Our
                foundation is driven by the core values of empowerment,
                education, and environmental sustainability. We strive to
                enhance farmer well-being by providing access to crucial
                resources, information, and support services. Through
                collaborations with local partners and stakeholders, we address
                the challenges faced by farmers, ensuring they have the tools
                they need to thrive. Empowering the next generation of farmers
                is a central focus of our work. We offer scholarships, training
                programs, and mentorship opportunities to equip young
                individuals with the skills and knowledge necessary for success
                in modern agriculture. Moreover, we bridge the knowledge gap by
                providing practical guidance and support to young farmers,
                helping them adapt to evolving farming practices and
                technologies. <br />
                <br />
                <a
                  href="/#/about"
                  className="btn btn-success text-decoration-none text-white fw-bold"
                >
                  Read More
                </a>
              </p>
            </div>
            <div className="image-part col-md-6 " data-aos="fade-left">
              <img src="/images/aboutus.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      {/* vision and mission */}
      {/* <section className="container-fluid mission-vision">
        <div className="container">
          <div className="row mission">
            <div className="col-md-6 mv-det text-center">
              <h1>Our Mission</h1>
              <p>
                The Venkateshwara Farmers Welfare Foundation is committed to
                being a force for positive change in the lives of farmers and
                their families. Through various initiatives, we strive to
                address critical needs and foster sustainable development within
                agricultural communities. Our impactful social programs
                encompass financial literacy workshops, disaster relief
                assistance, and advocacy efforts aimed at promoting fair
                agricultural policies. Moreover, we are dedicated to nurturing
                the next generation by providing scholarships, career
                counselling, and educational resources to ensure that the
                children of farmers have access to bright futures. Additionally,
                we empower young agricultural enthusiasts through training
                programs focused on sustainable practices, market access
                strategies, and digital tools, cultivating a new generation of
                innovative farmers. Recognizing the vital role of women in
                agriculture, we equip wives of farmers with skills and resources
                to generate income, fostering financial independence and
                bolstering rural families. Furthermore, we facilitate knowledge
                exchange by creating platforms for experienced and young farmers
                to connect, share best practices, and contribute to the
                continuous advancement of agricultural knowledge. Lastly, we
                advocate for sustainable practices that nurture the land,
                conserve resources, and ensure the long-term viability of
                agriculture for future generations. Through our comprehensive
                approach, we strive to create a brighter and more sustainable
                future for farming communities worldwide. By working
                collaboratively with farmers, communities, and stakeholders, the
                Venkateshwara Farmers Welfare Foundation strives to build a
                future where agriculture thrives, empowering rural families and
                contributing significantly to a secure and sustainable food
                system for all.
              </p>
            </div>
            <div className="col-md-6 mv-img align-self-center">
              <img src="/images/misin.jpg" alt="" />
            </div>
          </div>
          <div className="row vision">
            <div className="col-md-6 mv-img align-self-center">
              <img src="/images/vision.jpg" alt="" />
            </div>
            <div className="col-md-6 mv-det text-center">
              <h1>Our Vision</h1>

              <p>
                Our vision at the Venkateshwara Welfare Foundation is to
                cultivate a vibrant and resilient agricultural ecosystem where
                farmers, their families, and rural communities thrive. We
                envision a future where farmers are not only provided with the
                knowledge, resources, and fair market access necessary for
                prosperity but are also respected as stewards of the land.
                Moreover, we strive for a society where the children of farmers
                have equal opportunities for quality education, enabling them to
                pursue their aspirations and contribute meaningfully to society.
                Through our initiatives, we aim to empower young farmers to
                embrace innovative practices that ensure the sustainability and
                growth of agriculture for future generations. Additionally, we
                are committed to empowering the wives of farmers, providing them
                with opportunities for economic independence and fostering a
                strong and supportive rural family structure. By working towards
                these goals, we believe we can create a future where agriculture
                is not only sustainable but also a source of pride and
                prosperity for farming communities worldwide
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* <!-- ################# Events Start Here#######################---> */}
      <section className="bg-02">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2>Core Objective</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="wrapper">
                <div className="content">
                  <ol style={{ listStyle: "none" }}>
                    <li data-aos="fade-zoom-in">
                      <i className="fa fa-bone"></i>
                      <h3>Education</h3>
                      <p style={{ textAlign: "justify" }}>
                        Provide educational opportunities and resources for
                        farmers' children, ensuring they have a bright future.
                      </p>
                    </li>
                    <li data-aos="fade-zoom-in">
                      <i className="fa fa-graduation-cap"></i>
                      <h3>Training</h3>
                      <p style={{ textAlign: "justify" }}>
                        Equip young farmers with the knowledge and skills
                        necessary to succeed in modern agriculture.
                      </p>
                    </li>
                    <li data-aos="fade-zoom-in">
                      <i class="fa-solid fa-house"></i>{" "}
                      <h3>Cultural Preservation</h3>
                      <p style={{ textAlign: "justify" }}>
                        Preserve traditional farming knowledge and cultural
                        heritage within farming communities, recognizing the
                        value of indigenous practices and promoting cultural
                        identity and pride among farmers and their families.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 align-self-center text-center">
              <div className="wrapper  ">
                <div className="">
                  <img src="/images/education.png" width={350} height={450} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="wrapper">
                <div className="content">
                  <ol style={{ listStyle: "none" }}>
                    <li data-aos="fade-zoom-in">
                      <i class="fa-solid fa-wheat-awn"></i>{" "}
                      <h3>Community Development</h3>
                      <p style={{ textAlign: "justify" }}>
                        Foster social and community development initiatives that
                        strengthen the agricultural sector and create a
                        supportive environment for farmers and their families.
                      </p>
                    </li>
                    <li data-aos="fade-zoom-in">
                      <i class="fa-solid fa-building-columns"></i>{" "}
                      <h3>Wellbeing</h3>
                      <p style={{ textAlign: "justify" }}>
                        Promote the mental and physical wellbeing of farmers and
                        their families, addressing the unique challenges they
                        face.
                      </p>
                    </li>
                    <li data-aos="fade-zoom-in">
                      <i class="fa-solid fa-house"></i> <h3>Empowerment</h3>
                      <p style={{ textAlign: "justify" }}>
                        Empower farmers' wives through programs that enhance
                        their skills, knowledge, and economic independence.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Volunteer />

      {/* blogs */}
      <section className="bg-04">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2>Latest Blogs</h2>
              </div>
            </div>

            <div className="row justify-content-center">
              {data.map((item) => {
                return (
                  <>
                    <NavLink
                      to={item.link}
                      className="col-md-4 text-decoration-none"
                    >
                      <article>
                        <div className="card" data-aos="flip-left">
                          <div className="">
                            <img
                              src={item.img}
                              className="card-img-top"
                              height={230}
                            />
                          </div>
                          <div className="card-body"></div>
                          <div className="p-2">
                            <div className="">
                              <h4>{item.title}</h4>
                              <p>{item.desc}</p>
                            </div>
                            <div className="blog-admin text-center">
                              <div className="btn btn-success btn-sm">
                                <NavLink
                                  to={item.link}
                                  className="text-white text-decoration-none"
                                >
                                  Read More
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </NavLink>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* certification */}
      <div className="container py-4 text-center">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <h2>Certifications</h2>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-6 align-self-center">
            Venkateshwara Welfare Farmer Foundation is officially registered
            under the Government of India's Ministry of Corporate Affairs,
            Central Registration Centre. With its Certificate of Incorporation,
            the foundation stands as a recognized entity dedicated to supporting
            the welfare of farmers.
          </div>
          <div className="col-md-6">
            <img
              src="/images/certificate.png"
              className="border border-1 border-dark"
              width={400}
              alt=""
            />
          </div>
        </div> */}

        <div className="container mt-3 bg-success p-3 text-white">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <a href="/images/COI.pdf">
                <img
                  src="/images/certificate.png"
                  className="border border-1 border-dark img-fluid"
                  alt=""
                />
              </a>

              <p className="mt-4 ">
                <h5>Certificate Of Incorporation</h5>
                <p style={{ textAlign: "justify" }}>
                  {" "}
                  Venkateshwara Welfare Farmer Foundation is officially
                  registered under the Government of India's Ministry of
                  Corporate Affairs, Central Registration Centre. With its
                  Certificate of Incorporation, the foundation stands as a
                  recognized entity dedicated to supporting the welfare of
                  farmers.
                </p>
              </p>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <a href="/images/80g.pdf">
                <img
                  src="/images/80g.png"
                  className="border border-1 border-dark img-fluid"
                  alt=""
                />
              </a>

              <p className="mt-4  ">
                <h5> 80G Certification</h5>
                <p style={{ textAlign: "justify" }}>
                  We are proud to announce that Venkateshwara Farmer Welfare
                  Foundation has been officially granted an 80G Certificate by
                  the Income Tax Department. This certificate allows donors to
                  claim tax deductions on their contributions to our foundation,
                  making your support even more impactful.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
