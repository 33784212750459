import React from "react";
import "./blog.css";
import { NavLink } from "react-router-dom";
import Volunteer from "../volunteer/Volunteer";

const Blogs = () => {
  const data = [
    {
      id: 1,
      img: "/images/b.png",
      date: "5 june, 2024",
      title: "Securing a Brighter Future",
      desc: "Financial Literacy Workshops: Equipping farmers with financial management skills is crucial. Workshops on budgeting, saving, and accessing credit facilities can help them make informed financial decisions and weather economic uncertainties.",
      link: "/blogdetail",
    },
    {
      id: 2,
      img: "/images/b1.png",
      date: "5 june, 2024",
      title: "Elevating Farmers' Well-being",
      desc: "Affordable Healthcare Access: Many farmers lack access to affordable healthcare services. VWFF can partner with healthcare providers to offer subsidized medical check-ups, essential medication at reduced costs, or organize mobile health camps in rural areas.",
      link: "/blogdetails2",
    },
    {
      id: 3,
      img: "/images/Venkateshwara Farmers Welfare Foundation is transforming lives and cultivating a brighter tomorrow for farmers across India through comprehensive support programs and empowering initiatives (1).png",
      date: "5 june, 2024",
      title: "Cultivating the Future",
      desc: "The fertile fields of India hold immense potential, a promise of bountiful harvests that nourish generations. However, this promise hinges on a crucial factor: the next generation of farmers.",
      link: "/blogdetails3",
    },
  ];

  return (
    <>
      <section className="abt-01">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading-wrapper">
                <h3>Blogs</h3>
                <ol>
                  <li>
                    Home<i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>Blogs</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-04">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2>Latest Blogs</h2>
              </div>
            </div>

            {/* <div>
              <h3>
                Securing a Brighter Future: The Venkateshwara Farmers Welfare
                Foundation's Mission
              </h3>
              <p>
                Across India, the backbone of our nation rests on the tireless
                efforts of our farmers. Yet, the agricultural sector faces
                numerous challenges. The Venkateshwara Farmers Welfare
                Foundation (VFWF) stands tall amidst these challenges, with a
                mission to empower farmers, bridge knowledge gaps, and cultivate
                a thriving agricultural future.
              </p>
              <p>Enhancing the Lives of Farmers: A Holistic Approach</p>
            </div> */}

            <div className="row d-flex">
              {data.map((item) => {
                return (
                  <>
                    <NavLink
                      to={item.link}
                      className="col-md-4 text-decoration-none"
                    >
                      <article>
                        <div className="card">
                          <div className="">
                            <img
                              src={item.img}
                              className="card-img-top"
                              height={230}
                            />
                          </div>
                          <div className="card-body"></div>
                          <div className="p-2">
                            <div className="">
                              <h4>{item.title}</h4>
                              <p>{item.desc}</p>
                            </div>
                            <div className="blog-admin text-center">
                              <div className="btn btn-success btn-sm">
                                <NavLink
                                  to={item.link}
                                  className="text-white text-decoration-none"
                                >
                                  Read More
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </NavLink>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <Volunteer />
    </>
  );
};

export default Blogs;
