import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer className=" mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-content">
                <h2>Find Us</h2>
                <iframe
                  className="gmap_iframe border p-2"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14995.1934260002!2d73.843284!3d20.016976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddc1b308290909%3A0x95f4d9d4823cca8c!2sVenkateshwara%20Co-operative%20Power%20and%20Agro%20Processing%20Ltd.!5e0!3m2!1sen!2sus!4v1714901624111!5m2!1sen!2sus"
                ></iframe>
                <div className="mt-4 ">
                  <div className="footer-content">
                    <h2 className="text-dark">Follow Us</h2>
                    <ul>
                      <li>
                        <a href="" className="text-white">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/venkateshwarawelfare-foundation-8118a2304/"
                          className="text-white"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/VenkateshwarWF"
                          className="text-white"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/venkateshwarawelfare-foundation-8118a2304/"
                          className="text-white"
                        >
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 justify-content-center ps-2 ms-5">
              <div className="footer-content">
                <h2>Quick Links</h2>
                <ol style={{ listStyle: "none" }}>
                  <li>
                    <a href="/#/" className="text-decoration-none">
                      <i className="fa fa-angle-double-right"></i>Home
                    </a>
                  </li>
                  <li>
                    <a href="/#/about" className="text-decoration-none">
                      <i className="fa fa-angle-double-right"></i>About Us
                    </a>
                  </li>

                  <li>
                    <a href="/#/blogs" className="text-decoration-none">
                      <i className="fa fa-angle-double-right"></i>Blogs
                    </a>
                  </li>
                  <li>
                    <a href="/#/contact" className="text-decoration-none">
                      <i className="fa fa-angle-double-right"></i>Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="/#/news" className="text-decoration-none">
                      <i className="fa fa-angle-double-right"></i>News
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-content">
                <h2>About Foundation</h2>
                <p style={{ textAlign: "justify" }}>
                  Welcome to the Venkateshwara Welfare Foundation, dedicated to
                  uplifting farmers and agricultural communities worldwide. Our
                  foundation is driven by the core values of empowerment,
                  education, and environmental sustainability. We strive to
                  enhance farmer well-being by providing access to crucial
                  resources, information, and support services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <div className="container">
            <div className="copy-right-card text-white">
              <p>
                2024 &copy; All Rights Reserved Designed and developed by
                <a
                  className="text-white text-decoration-none"
                  href="https://www.theinnovativesolutions.in"
                >
                  The Innovative Solutions
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
